<template>
  <v-popover
    ref="popover"
    popoverClass="popover-comments"
    offset="0px"
    :placement="placement"
    :disabled="!lead.comments.list.length && !canEdit"
    :auto-hide="autoHide"
    :handleResize="true"
    @show="handleShowPopover"
  >
    <slot></slot>
    <template slot="popover">
      <LoadingComponent
        :style="{ position: 'absolute' }"
        v-if="!lead.comments.loaded"
      />
      <div
        v-else
        class="popover-comments-content"
        :class="{
          editable: editable,
          empty: !lead.comments.list.length,
          disabled: !canEdit
        }"
        @click="hundleContentClick"
      >
        <div class="user">
          {{ currentUser.first_name }} {{ currentUser.last_name }}
        </div>
        <div class="comments">
          <div
            class="comment"
            v-for="comment in sortedComments"
            :key="comment.id"
          >
            <div class="comment-header">
              <div class="left">
                <div class="user">{{ comment.created_by }}</div>
                <div class="date">{{ comment.created_at | time }}</div>
              </div>
              <div class="actions"></div>
            </div>
            <div class="text">{{ comment.body }}</div>
          </div>
        </div>
        <div class="new-comment">
          <form @submit.prevent="hundleSubmit">
            <b-form-textarea
              id="textarea"
              ref="commentTextarea"
              @input="handleInputResize"
              v-model="commentToAdd.text"
            ></b-form-textarea>
            <div class="action">
              <b-button
                size="sm"
                variant="outline-success"
                @click="handleCancel"
              >
                Annuler
              </b-button>
              <b-button
                type="submit"
                size="sm"
                variant="success"
                :disabled="!commentToAdd.text.length"
              >
                {{
                  lead.comments.list && lead.comments.list.length
                    ? 'Répondre'
                    : 'Commenter'
                }}
              </b-button>
            </div>
          </form>
        </div>
      </div>
    </template>
  </v-popover>
</template>

<script>
import Vue from 'vue'
import { VPopover } from 'v-tooltip'
import { mapActions, mapGetters } from 'vuex'
import moment from 'moment'
import LoadingComponent from '@/components/ui/LoadingComponent'
Vue.component('v-popover', VPopover)

export default {
  name: 'TooltipComment',
  components: {
    LoadingComponent
  },
  props: {
    placement: {
      type: String,
      default: 'auto'
    },
    lead: { required: true },
    canEdit: { default: false }
  },
  data() {
    return {
      commentToAdd: {
        text: ''
      },
      editable: false
    }
  },
  methods: {
    ...mapActions(['addCommentToLead', 'fetchComment']),
    hundleContentClick() {
      if (this.canEdit) {
        this.editable = true
      }
    },
    async hundleSubmit() {
      await this.addCommentToLead({ lead: this.lead, ...this.commentToAdd })
      this.$emit('valueChange')
      this.reset()
    },
    handleCancel() {
      if (this.commentToAdd.text.length) {
        this.commentToAdd.text = ''
        return
      }
      this.$refs['popover'].hide()
    },
    reset() {
      this.commentToAdd = {
        text: ''
      }
      this.handleInputResize()
    },
    handleShowPopover() {
      this.reset()
      this.editable = false
      this.fetchComment(this.lead)
    },
    handleInputResize() {
      if (this.$refs['commentTextarea']) {
        const el = this.$refs['commentTextarea'].$el
        this.$nextTick(() => {
          if (el) {
            el.style.height = ''
            el.style.height = el.scrollHeight + 'px'
          }
        })
      }
    }
  },
  computed: {
    ...mapGetters(['currentUser']),
    autoHide: function() {
      return this.commentToAdd.text.length ? false : true
    },
    sortedComments: function() {
      return [...this.lead.comments.list].sort((a, b) => {
        const dataA = moment(a.created_at, 'YYYY-MM-DD HH:mm:ss')
        const dataB = moment(b.created_at, 'YYYY-MM-DD HH:mm:ss')
        return dataB.diff(dataA)
      })
    }
  },
  filters: {
    time: function(value) {
      if (!value) return ''
      return moment(value, 'YYYY-MM-DD HH:mm:ss').format('LT DD MMM')
    }
  }
}
</script>

<style lang="scss">
.tooltip {
  &.popover-comments {
    opacity: 1;
    display: block !important;
    z-index: 10000;
    max-height: 380px;
    width: 280px;
    border-radius: 0 8px 8px 0;
    box-shadow: 0 2px 6px 2px rgba(60, 64, 67, 0.15);
    max-height: inherit;
    overflow-y: auto;
    overflow-x: hidden;

    .tooltip-inner {
      background: black;
      color: white;
      border-radius: 16px;
      text-align: left;
      max-width: unset;
      min-height: 80px;
      background: #fff;
      color: black;
      padding: 0;
      box-shadow: 0 5px 30px rgba(black, 0.1);

      .popover-comments-content {
        cursor: pointer;

        &.disabled {
          cursor: default;
        }
        > .user {
          display: none;
          color: #656565;
          padding: 4px 2px;
          margin-left: 16px;
        }

        .comments {
          max-height: 200px;
          overflow-y: auto;
          overflow-x: hidden;
          .comment {
            padding: 8px 0;
            margin: 0 12px;
            padding: 8px 0;
            margin: 0 12px;
            font-size: 12px;
            font-family: Ubuntu, sans-serif;
            color: #656565;
            border-top: 1px solid #dadce0;
            .comment-header {
              display: flex;
              .left {
                .user {
                  font-weight: 700;
                }
                .date {
                  color: #9e9e9e;
                  font-size: 11px;
                }
              }
            }

            .text {
              white-space: pre-wrap;
            }
            &:first-child {
              border-top: none;
            }
          }
        }

        .new-comment {
          display: none;
          margin: 0;
          padding: 8px 16px;
          text-align: right;
          border-top: 1px solid #e5e5e5;
          background-color: #f7f7f7;
          .action {
            margin-top: 3px;
            button {
              font-size: 11px;
              font-weight: 700;
              padding: 0.12rem 0.5rem;
              &:first-child {
                margin-right: 4px;
              }
            }

            .btn-outline-success:not(:disabled):not(.disabled):active:focus,
            .btn-outline-success:not(:disabled):not(.disabled).active:focus,
            .show > .btn-outline-success.dropdown-toggle:focus {
              box-shadow: 0 0 0 0.1rem rgba(40, 167, 69, 0.5);
            }
            .btn-outline-success:not(:disabled):not(.disabled):active,
            .btn-outline-success:not(:disabled):not(.disabled).active,
            .show > .btn-outline-success.dropdown-toggle {
              color: #28a745;
              background-color: #fff;
              border-color: #28a745;
            }
            .btn-outline-success:hover {
              color: #28a745;
              background-color: #fff;
              border-color: #28a745;
            }
            .btn-outline-success:focus,
            .btn-outline-success.focus {
              -webkit-box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
              box-shadow: 0 0 0 0.1rem rgba(40, 167, 69, 0.5);
            }
          }
          form {
            textarea {
              height: 32px;
              min-height: 32px;
              resize: none;
              overflow: hidden;
              line-height: 1rem;
              font-size: 0.75rem;
            }
          }
        }

        &.editable {
          cursor: default;
          .new-comment {
            display: block;
          }
        }

        &.empty {
          cursor: default;
          .new-comment {
            display: block;
          }
          .user {
            display: block;
          }
        }
      }
    }

    .tooltip-arrow {
      display: none;
    }

    &[x-placement^='top'] {
      margin-bottom: 5px;
    }

    &[x-placement^='bottom'] {
      margin-top: 5px;
    }

    &[x-placement^='right'] {
      margin-left: 0px;
      border-left: 6px solid #ffe436;
    }

    &[x-placement^='left'] {
      margin-right: 5px;
    }

    &[aria-hidden='true'] {
      visibility: hidden;
      opacity: 0;
      transition: opacity 0.15s, visibility 0.15s;
    }

    &[aria-hidden='false'] {
      visibility: visible;
      opacity: 1;
      transition: opacity 0.15s;
    }
  }
}
</style>
